<template>
  <b-modal :id="lModalId" ref="attention-modal" ok-only>
    <b-container>
      <b-row class="justify-content-center">
        <div class="img">
          <img :src="resolve_img_url('completed-graphic.svg')"/>
          <div class="img--internal">
            <img :src="resolve_img_url('MDI-send.svg')"/>
          </div>
        </div>
      </b-row>
      <b-row class="justify-content-center mt-5">
        <h2>
          {{ $props.title }}
        </h2>
      </b-row>
      <b-row class="mt-3">
        <slot name="description"> </slot>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-center">
        <b-button @click="onActioHandler(true)" variant="outline-primary">
          {{ $props.submitButtonText ? $props.submitButtonText : "Voltar" }}
        </b-button>

        <b-button @click="onActioHandler(false)" class="ml-4" variant="primary">
          {{ $props.cancelButtonText ? $props.cancelButtonText : "Confirmar" }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import resolve_img_url from "../../utils/image/image-utils";

export default {
  props: [
    "title",
    "modalId",
    "description",
    "buttonText",
    "cancelButtonText",
    "submitButtonText",
    "onAction",
  ],
  setup(props, ctx) {
    const lModalId = props.modalId ? props.modalId : "send-modal";

    const closeModal = function() {
      ctx.refs["attention-modal"].hide();
    };

    const onActioHandler = function(event) {
      props.onAction(event);
      closeModal();
    };

    return { lModalId, onActioHandler };
  },
  methods: {
    resolve_img_url: resolve_img_url,
  },
};
</script>

<style lang="scss" scoped>
.img {
  position: relative; 
  &--internal {
    position: absolute; 
    left: 29%;
    top: 25%;
  }
}
</style>
