var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
          [
            _vm.DocumentType.EXTRACT,
            _vm.DocumentType.INCOME_REPORT ].includes(_vm.form.fileType)
        )?_c('div',{staticClass:"info-header mb-3"},[_c('div',[_c('p',{staticClass:"p3 color-green-2"},[_vm._v(" Durante a importação, o sistema agrupará todos os números presentes no nome do arquivo para identificar o CRM. ")]),_c('p',{staticClass:"p3 color-green-2"},[_vm._v(" Por exemplo, se o arquivo for nomeado ‘"+_vm._s(_vm.nameFileMsg)+"’, o CRM será ‘5661’. ")])])]):_vm._e(),_c('b-form',{staticClass:"alter-form",attrs:{"id":"alter-form"},on:{"submit":_vm.onSubmit}},[_c('b-card',{staticClass:"custom-card flex-grow-1",attrs:{"border-variant":"light"}},[_c('b-form-row',[_c('b-col',[_vm._v(" Tipo ")]),_c('b-col',[_c('b-form-select',{attrs:{"state":_vm.validateField('fileType'),"options":_vm.fileTypeOptions},on:{"change":function($event){return _vm.onChangeFileType(_vm.form.fileType)}},model:{value:(_vm.form.fileType),callback:function ($$v) {_vm.$set(_vm.form, "fileType", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.fileType"}})],1)],1),(_vm.form.fileType === _vm.DocumentType.CALENDAR_INVOICES)?_c('b-form-row',[_c('b-col',[_vm._v(" REFERÊNCIA ")]),_c('b-col',[_c('b-form-input',{model:{value:(_vm.form.referency),callback:function ($$v) {_vm.$set(_vm.form, "referency", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.referency"}})],1)],1):_vm._e(),(
          ![
            _vm.DocumentType.EXTRACT,
            _vm.DocumentType.INCOME_REPORT,
            _vm.DocumentType.ECONOMIC_PANEL,
            _vm.DocumentType.CALENDAR_INVOICES,
            _vm.DocumentType.ORGANIZATIONAL_CHART ].includes(_vm.form.fileType)
        )?_c('b-form-row',[_c('b-col',[_vm._v(" CRM ")]),_c('b-col',[_c('b-form-input',{attrs:{"type":"number","state":_vm.validateField('crm')},model:{value:(_vm.form.crm),callback:function ($$v) {_vm.$set(_vm.form, "crm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.crm"}})],1)],1):_vm._e(),(_vm.form.fileType !== _vm.DocumentType.CALENDAR_INVOICES)?_c('b-form-row',[(_vm.form.fileType == _vm.DocumentType.EXTRACT)?_c('b-col',[_vm._v(" Ano Calendário ")]):(_vm.form.fileType == _vm.DocumentType.ORGANIZATIONAL_CHART)?_c('b-col',[_vm._v(" ATUALIZADO EM ")]):_c('b-col',[_vm._v(" Competência ")]),(_vm.form.fileType == _vm.DocumentType.INCOME_REPORT)?_c('b-col',[_c('custom-mask-input',{attrs:{"initialValue":_vm.form.competency,"placeHolder":"AAAA","options":_vm.yearOnlyMask,"state":_vm.validateFieldLength('competency', 4)}})],1):_c('b-col',[_c('custom-mask-input',{attrs:{"initialValue":_vm.form.competency,"placeHolder":"MM/AAAA","options":_vm.monthYearDateMask,"state":_vm.validateFieldLength('competency', 7)}})],1)],1):_vm._e(),_c('b-form-row',[_c('b-col',[_c('UploadFile',{ref:"upload-file-component",attrs:{"accept":_vm.form.fileType !== _vm.DocumentType.SUPPORT_FUND
                ? 'application/pdf'
                : 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',"description":"Anexar documento","msgErro":"É necessário inserir um anexo com a comprovação.","state":_vm.validateFieldFile('files'),"required":true,"multiple":true,"sizeFiles":_vm.sizeMaxFile,"custonMaxheight":true},on:{"updateFile":_vm.eventToUpdateFile,"sizeFilesValid":_vm.eventSizeFilesValid,"updateFileZip":_vm.eventToUpdateFileZip}})],1)],1)],1),_c('div',{staticClass:"w-100 d-flex"},[_c('b-button',{staticClass:"mt-4",attrs:{"form":"alter-form","type":"submit","variant":"primary","id":"submitButtonText"}},[_vm._v(" Enviar ")]),(this.visibleDownloadReport)?_c('div',{staticClass:"ml-4 mt-4"},[_c('div',[_c('div',{staticClass:"custom-button-chart",on:{"click":_vm.onDownloadReportHanlder}},[_c('img',{attrs:{"src":require("@/assets/icons/MDI-download-outline.svg")}}),_c('div',{staticClass:"custom-text-chart "},[_vm._v("Baixar relatório")])])])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }