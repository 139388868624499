import axios from "../http-client/axios";
import { OPETH_URL } from "../urls/base-urls";
import mapperToDocumentRequest from "./document-mapper";

export async function documentPDFUpload(formData) {
  const mappedFormData = mapperToDocumentRequest(formData);
  let formDataInstance = new FormData();

  if (!["INCOME_REPORT", "EXTRACT"].includes(formData.fileType)) {
    formDataInstance.append("file", mappedFormData.files[0]);
    formDataInstance.append("crm", mappedFormData.crm);
  }

  formDataInstance.append("type", mappedFormData.fileType);
  formDataInstance.append("year", mappedFormData.year);
  if (mappedFormData.month)
  formDataInstance.append("month", mappedFormData.month);

  let resp = null;
  if (["INCOME_REPORT", "EXTRACT"].includes(formData.fileType)) {
    resp = await axios.post(
      `${OPETH_URL}/file-zip`,
      // `http://localhost:8295/file-zip`,
      requestBuilder(
        mappedFormData,
        Array.from([formData.fileZip]),
      )
    );
  } else {
    resp = await axios({
      url: `${OPETH_URL}`,
      // url: `http://localhost:8295`,
      method: "post",
      data: formDataInstance,
      headers: { "content-type": "application/json" },
    });
  }  

  return resp?.data;
}

function requestBuilder(
  formData,
  attachments = [],
) {

  const objectRequest = {
    crm: formData.crm,
    type: formData.fileType,
    year: formData.year,
    month: formData.month,
  };
  let formRequest = new FormData();
  const blob = new Blob([JSON.stringify(objectRequest)],{
    type: 'application/json'
  });
  formRequest.append("model", blob);
  
  if (attachments.length > 0) { 
    attachments.forEach(file=> formRequest.append("files", file));
  }

  return formRequest
}

export async function sheetUpload(formData) {
  const mappedFormData = mapperToDocumentRequest(formData);
  let formDataInstance = new FormData();

  formDataInstance.append("file", mappedFormData.files[0]);
  formDataInstance.append("crm", mappedFormData.crm);
  formDataInstance.append("year", mappedFormData.year);
  formDataInstance.append("month", mappedFormData.month);

  const resp = await axios({
    url: `${OPETH_URL}/excel-support-fund`,
    method: "post",
    data: formDataInstance,
    headers: { "content-type": "application/json" },
  });

  return resp?.data;
}
