<script>
import Cleave from "cleave.js";

export default {
  name: "custom-mask-input",
  render(h) {
    return h("input", {
      type: "text",
      class: "form-control " + this.getStateClass(),
      ref: "root",
    });
  },
  props: {
    initialValue: { type: String },
    options: {
      type: Object,
      default: () => ({}),
    },
    raw: {
      type: Boolean,
      default: true,
    },
    field: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      cleave: null,
      onValueChangedFn: null,
    };
  },
  mounted() {
    if (this.cleave) return;
    this.cleave = new Cleave(this.$refs.root, this.getOptions(this.options));
  },
  methods: {
    getStateClass() {
      let state = this.$props.state;
      if (state == null || state == undefined) return state;

      return !state ? "is-invalid" : "is-valid";
    },
    getOptions(options) {
      this.onValueChangedFn = options.onValueChanged;
      return Object.assign({}, options, {
        onValueChanged: this.onValueChanged,
      });
    },
    onValueChanged(event) {
      if (typeof this.onValueChangedFn === "function") {
        this.onValueChangedFn.call(this, event, this.$props.field);
      }
    },
  },
  watch: {
    initialValue: {
      immediate: true,
      handler(newValue) {
        if (this.cleave && this.cleave.setRawValue)
          this.cleave.setRawValue(newValue);
      },
    },
    options: {
      deep: true,
      handler(newOptions) {
        this.cleave.destroy();
        this.cleave = new Cleave(this.$refs.root, this.getOptions(newOptions));
        this.cleave.setRawValue(this.modelValue);
      },
    },
  },
  beforeUnmount() {
    if (!this.cleave) return;
    this.cleave.destroy();
    this.cleave = null;
    this.onValueChangedFn = null;
  }
};
</script>
