export default function mapperToDocumentRequest(formData) {
  try {
    const competency = String(formData?.competency);
    const crudeData = competency.split("/");
    let preparedData = {};
    if (crudeData.length == 2) {
      preparedData.month = crudeData[0]
      preparedData.year = crudeData[1];
    } else {
      preparedData.year = crudeData[0];
    }
    return {
      ...formData,
      ...preparedData
    };
  } catch (error) {
    console.error("Error on mapperToDocumentRequest", error);
  }
}
